<template>
	<div class="capacity-report">
		<div class="table-box">
			<div class="title">
				<span class="icon">
					<i :class="menuIcon"></i>
				</span>
				<span class="txt">{{menuName}}</span>
			</div>
			<el-button class="btn" @click="beginBtnClick()" type="success" icon="el-icon-video-play">
				开始工单
			</el-button>
			<el-table 
				class="table"
				ref="table"
				:data="tableData"
				:row-class-name="tableRowClassName"
				max-height="650"
				style="width: 100%;"
			>
				<el-table-column label="工单号" prop="workOrderNo" min-width="12.5%" align='center'></el-table-column>
				<el-table-column label="产品编码" prop="productionCode" min-width="12.5%" align='center'></el-table-column>
				<el-table-column label="工序名称" prop="procedureName" min-width="12.5%" align='center'></el-table-column>
				<el-table-column label="状态" min-width="12.5%" align='center'>
					 <template slot-scope="scope">
						<el-tag :type="scope.row.type === 1 ? 'primary' : 'info'">
							{{scope.row.type | formatType}}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="计划数量" prop="planCount" min-width="12%" align='center'></el-table-column>
				<el-table-column label="完成数量" prop="outNum" min-width="12%" align='center'></el-table-column>
				<el-table-column label="不良数量" prop="badnessNum" min-width="12%" align='center'></el-table-column>
				<el-table-column label="操作" min-width="15%" align='center'>
					<template slot-scope="scope">	
						<el-button @click="endBtnClick(scope.row.prid)" :disabled="scope.row.type == 2" type="danger" icon="el-icon-video-pause" size="mini">
							结 束
						</el-button>
					</template>	
				</el-table-column>

			</el-table>
		</div>
				
		<el-dialog
			class="start-workorder"
			title="选择开始工单"
			:visible.sync="dialogFormVisible"
			width="60%"
			@close="emptyStartWorkOrderForm"
			center>
			<div class="btn">
				<el-button @click="multipleChange">多人刷卡</el-button>
			</div>
			<el-row :gutter="20">
				<el-col :span="span">
					<el-card style="height: 30rem;">
						<div style="height: 100%;">
							<div class="title">工单列表</div>
							<el-form :model="workerOrderListForm" ref="workerOrderListFormRef">
								<el-form-item prop="workOrderId">
									<div v-for="item in workOrederList">
										<el-radio-group v-model="workerOrderListForm.workOrderId" size="medium" @change="getPlanProcedureList(item.pid)">
											<el-radio border :label="item.workOrderNo" :key="item.pid" :value="item.pid"></el-radio>
										</el-radio-group>
									</div>
								</el-form-item>
							</el-form>
						</div>
					</el-card>
				</el-col>
				
				<el-col :span="span">
					<el-card style="height: 30rem;">
						<div style="height: 100%;">
							<div class="title">工序列表</div>
							<el-form :model="procedureListForm" ref="procedureListFormRef">
								<el-form-item prop="selectProcedureId">
									<el-checkbox-group v-model="procedureListForm.selectProcedureId">
										<div v-for="item in processIdList">
											<el-checkbox :label="item" :key="item.procedureId">{{item.procedureName}}</el-checkbox>
										</div>
									</el-checkbox-group>
								</el-form-item>
							</el-form>
						</div>
					</el-card>
				</el-col>
				
				<el-col :span="span" v-if="isMultiple">
					<el-card style="height: 30rem;">
						<div style="height: 100%;">
							<div class="title">扫描/刷卡</div>
							<el-form :model="cardNoForm" label-width="60px" label-position="left" ref="cardNoFormRef">
								<el-form-item label="卡号" class="txt-form-box" prop="cardNo">
									<el-input v-model="cardNoForm.cardNo" placeholder="请刷卡" @keyup.enter.native="checkBlur" clearable></el-input>
								</el-form-item>
								<el-form-item label="文本" class="txt-form-box" prop="remain">
									<el-input type="textarea" class="remain-form-item" v-model="cardNoForm.remain" :rows="6" readonly clearable></el-input>
								</el-form-item>
							</el-form>
						</div>
					</el-card>
				</el-col>
			</el-row>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="confirmBtnClick()">确 定</el-button>
				<el-button @click="cancelBtnClick()">取 消</el-button>
			</div>
		</el-dialog>


		<el-dialog 
			class="end-work-dialog"
			title="填写结束工单信息"
			:visible.sync="dialogFormVisible1"
			width="30%"
			center>
			<el-form 
				:model="endForm"
				ref="endForm"
				:rules="endFormRules"
				style="margin: 0 auto;width: 25rem;"
				label-position="left">
				<el-form-item label="产出数量" :label-width="formLabelWidth" class="form-item-box" prop="endForm.outNum">
					<el-input v-model="endForm.outNum" placeholder="0"></el-input>
				</el-form-item>
				<el-form-item label="不良数量" :label-width="formLabelWidth" class="form-item-box" prop="outForm.badnessNum">
					<el-input v-model="endForm.badnessNum" placeholder="0"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="endWorkOrder()">确 定</el-button>
				<el-button @click="dialogFormVisible1 = false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getProcessList,
		getStartProcessList,
		getProcedureList,
		startWorkOrder,
		finishWorkOrder
	} from 'network/capacityReport'
	export default {
		name: "capacityReport",
		data() {
			return {
				planId: -1, //工单ID
				procedureId: -1, //工序ID
				// 是否多人刷卡
				isMultiple: false,
				span: 12,
				userId: window.sessionStorage.getItem("userId"),
				tableData: [], //表格数据
				tableHeight: 0, //表格高度
				dialogFormVisible: false, //开始工单弹窗是否显示
				workerOrderListForm: {
					workerOrderId: ""
				},
				dialogFormVisible1: false, //结束工单弹窗是否显示
				formLabelWidth: '120px', //表单label宽度
				//工单列表表单
				workOrederForm: {
					resource: []
				},
				//工序列表表单
				procedureListForm: {
					selectProcedureId: [], //选择工序的复选框
				},
				//刷卡卡号表单
				cardNoForm: {
					//刷卡卡号
					cardNo: "",
					//刷卡后提示的
					remain: "",
				},
				workOrederList: [], //开始工单列表
				chooseWorkOrder: '', //选择工单的单选
				processIdList: [], //选择功工序的ID
				//结束工单表单
				endForm: {
					outNum: 0, //产出数量
					badnessNum: 0, //不良数量
				},
				endFormRules: {
					outNum: {
						required: true,
						message: '请输入产出数量',
						trigger: 'blur'
					},
					badnessNum: {
						required: true,
						message: '请输入不良数量',
						trigger: 'blur'
					}
				},
				//结束工单ID
				endPrid: -1,
				//是否可以结束工单
				isEnd: false
			}
		},
		filters: {
			formatType(val) {
				return val == 1 ? "进行中" : "已结束"
			}
		},
		props: {
			menuIcon: {
				type: String,
				default: ""
			},
			menuName: {
				type: String,
				default: ""
			}
		},
		created() {
			this.getTabelData();
		},
		methods: {
			getTabelData() {
				let obj = {
					eqId: sessionStorage.getItem("eqId"),
					stationId: sessionStorage.getItem("stationId")
				}
				getProcessList(obj).then(res => {
					this.tableData = res.data.data;
				})
			},
			//表格隔行变色
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 === 1) {
					return 'success-row';
				}
				return '';
			},
			//请求开始工单数据
			beginBtnClick() {
				this.dialogFormVisible = true
				let obj = {
					eqId: sessionStorage.getItem("eqId")
				}
				getStartProcessList(obj).then(res => {
					this.workOrederList = res.data.data
				})
			},
			multipleChange() {
				this.isMultiple = true
				this.span = 8
			},
			//刷卡获取卡号
			checkBlur(e) {
				if(e.target.value == '' || e.target.value == null)
				return
				this.cardNoForm.remain += e.target.value + ",";
				this.cardNoForm.cardNo = "";
			},
			//根据工单ID查询工序列表
			getPlanProcedureList(id) {
				this.planId = id
				let obj = {
					planId: id
				}
				getProcedureList(obj).then(res => {
					this.processIdList = res.data.data
				})
			},
			// 开始工单
			confirmBtnClick() {
				this.dialogFormVisible = false
				//工序列表处理
				let arr = []
				if (this.procedureListForm.selectProcedureId != null) {
					this.procedureListForm.selectProcedureId.forEach(item => {
						arr.push(item.procedureId)
					})
				}else {
					this.$message.error("工序列表不能为空！")
				}
				this.procedureId = arr.join(","); //字符分割 
				
				//卡号列表处理
				var location = this.cardNoForm.remain.lastIndexOf(",");
				var cardList = this.cardNoForm.remain.substring(0, location)
				
				let obj = {
					planId: this.planId,
					procedureId: this.procedureId,
					deptId: sessionStorage.getItem("stationId"),
					userId: this.userId,
					cardNo: cardList
				}
				startWorkOrder(obj).then(res => {
					if (res.data.code == 0) {
						this.$message.success(res.data.msg)
						this.getTabelData()
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			cancelBtnClick() {
				this.dialogFormVisible = false
			},
			emptyStartWorkOrderForm() {
				this.$refs.workerOrderListFormRef.resetFields()
				this.$refs.procedureListFormRef.resetFields()
				this.$refs.cardNoFormRef.resetFields()
				this.isMultiple = false
				this.span = 12
			},
			// 结束按钮点击事件
			endBtnClick(prid) {
				this.endPrid = prid
				this.dialogFormVisible1 = true
			},
			//结束工单
			endWorkOrder() {
				let obj = {
					prid: this.endPrid,
					outNum: this.endForm.outNum,
					badnessNum: this.endForm.badnessNum
				}
				// console.log("工序ID"+obj.prid+"产出数量"+obj.outNum+"不良数量"+obj.badnessNum)
				finishWorkOrder(obj).then(res => {
					if (res.data.code == 0) {
						this.$message.success(res.data.msg);
						this.getTabelData();
						this.dialogFormVisible1 = false;
						this.isEnd = true;
					} else {
						this.$message.error(res.data.msg);
					}
				})
			}
		}
	}
</script>

<style lang="scss">
.txt-form-box>label {
	font-size: 1rem !important;
	font-family: PingFang SC !important;
	font-weight: normal !important;
	color: #606266 !important;
}
.el-checkbox__inner {
	width: 1rem;
	height: 1rem;
}
.el-dialog__title {
	font-size: 1.25rem !important;
	margin: 1.25rem 0;
}

.el-checkbox__inner::after {
	height: 0.5rem !important;
	left: 0.3rem !important;
}
.remain-form-item .el-textarea__inner {
	color: red !important;
}
.el-checkbox__label {
	font-size: 1.125rem !important;
}
.end-work-dialog .el-dialog__title {
	font-size: 1.25rem !important;
	letter-spacing: 2px !important;
}
.form-item-box label {
	font-size: 1.125rem !important;
	color: #303133;
}
.card-box {
	margin: 1.25rem 0;
}
/*表格字体*/
.el-table {
	font-size: 1.375rem !important;
}
/*表头样式*/
.el-table th {
	background: rgba(245,247,247,1) !important;
	font-size: 1.375rem !important;
	font-family: PingFang SC !important;
	font-weight: 500 !important;
	color: rgba(58,132,171,1) !important;
}
.el-table td, .el-table th {
	height: 3.75rem !important;
	padding: 0 !important;
}
.el-table .cell {
	padding: 0.625rem 0 !important;
}
.el-message__content {
	font-size: 1.125rem !important;
}
</style>
<style lang="scss" scoped>
.capacity-report {
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	border-radius: 0.625rem;
	box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
	position: relative;
	.title {
		width: 100%;
		display: flex;
		padding: 0.625rem;
		.icon {
			display: inline-block;
			width: 2.5rem;
			height: 2.5rem;
			text-align: center;
			line-height: 2.5rem;
			border-radius: 0.625rem;
			margin-right: 1.25rem;
			background:linear-gradient(0deg, rgba(41,187,192,1) 0%, rgba(73,208,206,1) 100%);
			i {
				font-size: 1.375rem;
				font-weight: 500;
				color: white;
			}
		}
		.txt {
			height: 2.5rem;
			font-size: 1.625rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(58,132,171,1);
			line-height: 2.5rem;
			letter-spacing: 3px;
		}
	}
	.el-card {
		overflow: hidden !important;
		overflow-y: scroll !important;
		.title {
			font-size: 1.25rem !important;
			font-family: PingFang SC;
			font-weight: normal;
			color: #606266;
			letter-spacing: 2px;
		}
	}
	.el-dialog {
		position: relative;
		.btn {
			position: absolute;
			top: 20px;
		}
	}
	.table-box {
		width: 100%;
		height: 100%;
		// position: relative;
		.btn {
			position: absolute;
			top: 0.5rem;
			right: 0.625rem;
		}
		.table {
			width: 100%;
			height: 100%;
			border-top: 1px solid #EBEEF5;
			overflow: hidden;
			overflow-y: scroll;
		}
	}
	.start-workorder {
		display: flex;
	}
}
</style>
